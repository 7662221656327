import React from "react";
import Tech from "./Tech";
import TechB from "./TechB";
import Techc from "./Techc";
import Techd from "./Techd";
import Teche from "./Teche";
import {Helmet} from "react-helmet"
export default function TechnologyM() {
  return (
    <div>
       <Helmet>
                <meta charSet="utf-8" />
                <title>React   | Destino Infotech</title>
                <meta name="description" content="Helmet application" />
                <link rel="canonical" href="http://mysite.com/example" />
            </Helmet>
      <Tech />
      <TechB />
      <Techc />
      <Techd />
      <Teche />
    </div>
  );
}
