import React from "react";
import Grid from "@mui/material/Grid";
import Angular from "./images/Angular_71x53.png";
import Aws from "./images/aws.png";
import Net from "./images/Net.png";
import Node from "./images/nodejs.png";
import Flu from "./images/Flutter.png";
import Java from "./images/Java.png";
import Javas from "./images/JavaScript.png";
import Php from "./images/PHP.png";
import Python from "./images/pyhton.png";
import Cloud from "./images/Google Cloude.png";
import Obj from "./images/ObjectiveC.png";
import Swift from "./images/Swift.png";
import Type from "./images/Typescript.png";
import Vue from "./images/Vue.png";
import Kot from "./images/Kotlin.png";
import Re from "./images/Reactjs.png";

const gridData = [
  { image: Aws, alt: "AWS", title: "AWS" },
  { image: Node, alt: "Node.js", title: "Node.js" },
  { image: Java, alt: "Java", title: "Java" },
  { image: Flu, alt: "Flutter", title: "Flutter" },
  { image: Javas, alt: "JavaScript", title: "JavaScript" },
  { image: Angular, alt: "Angular.js", title: "Angular.js" },
  { image: Cloud, alt: "Google Cloud", title: "Google Cloud" },
  { image: Obj, alt: "Objective-C", title: "Objective-C" },
  { image: Swift, alt: "Swift", title: "Swift" },
  { image: Type, alt: "TypeScript", title: "TypeScript" },
  { image: Vue, alt: "Vue.js", title: "Vue.js" },
  { image: Kot, alt: "Kotlin", title: "Kotlin" },
  { image: Net, alt: ".NET", title: ".NET" },
  { image: Php, alt: "PHP", title: "PHP" },
  { image: Python, alt: "Python", title: "Python" },
  { image: Re, alt: "React.js", title: "React.js" },
];

export default function Remote() {
  return (
    <div className="Remote-sec about-remote">
      <div className="container">
        <div className="heading">
          <h6 className="rea">DEVELOPERS</h6>

          <h2 className="head">Our Remote Developer's Expertise</h2>
          <p className="pra2">
            Discover a diverse pool of remote developers equipped with a
            spectrum of skills, from website development to app integration. Our
            experts are proficient in crafting bespoke solutions for your
            business needs.
          </p>
        </div>
        <div className="grid-layout-main">
          <Grid container spacing={2}>
            {gridData.map((item, index) => (
              <Grid item xs={12} sm={4} md={3} lg={2} key={index}>
                <div className="grid-item-box">
                  <img src={item.image} alt={item.alt} />
                  <h4>{item.title}</h4>
                </div>
              </Grid>
            ))}
          </Grid>
        </div>
      </div>
    </div>
  );
}
