import React from "react";
import "./Technology.scss";
import { Drawer, Grid } from "@mui/material";
import Header from "../Home/Header";
import ReactImg from "./T-img/ReactJS Banner Image.png";
import FormButton from "../Home/FormButton";

export default function Tech() {
 

  return (
    <>
      <div>
        <Header />
        <Drawer />
        <div className="ser-main">
          <div className="ser-container">
            <Grid
              container
              spacing={3}
              sx={{ paddingLeft: 3, paddingRight: 3 }}
            >
              <Grid item xs={12} sm={12} md={6}>
                <h1 className="ser-head">
                  Hire
                  <span className="color">
                    {" "}
                    Hiring React JS <br />
                     Developers {" "}
                  </span>{" "}
                  Rapidly
                </h1>
                <p className="ser-pra">
                Speed up your project timelines by swiftly bringing aboard top-notch React JS developers. Our streamlined process ensures quick access to skilled professionals, empowering your team to meet deadlines and exceed project expectations.
                </p>

                <h5>Efficient Onboarding Process</h5>
                <h5>Smooth Integration Support</h5>
                <h5>Performance-Oriented Solutions</h5>
                <h5>Flexible Engagement Models</h5>
                <h5>Customized Development Approaches</h5>
                <FormButton/>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                {" "}
                <img src={ReactImg} alt={""} />
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
}
