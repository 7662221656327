import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Home/Home";

import ContactM from "./Contact/ContactM";
import AboutM from "./AboutUs/AboutM";
import Servicem from "./Services/Servicem";
import React from "./Technology/React";
import Mobile from "./TechnologyHover/Mobile"
import Angular from "./TechnologyHover/Angular";
import Vue from "./TechnologyHover/Vue";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<AboutM />} />
          <Route path="/contact" element={<ContactM />} />
          <Route path="/service" element={<Servicem />} />
          <Route path="/React" element={<React />} />
          <Route path="/mobile" component={Mobile} element={<Mobile/>} />
          <Route path="/angular" component={Angular} element={<Angular/>} />
          <Route path="/Vue" component={Vue} element={<Vue/>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
