import React from "react";
import "./About.scss";
import { Grid } from "@mui/material";
// import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import Culture from "./img/united.png";
import continuous from "./img/continuous-improvement.png";
import flexible from "./img/flexible.png";

const coreValues = [
  {
    title: "Collaborative Culture",
    description:
      "Become part of a dynamic community that prioritizes collaboration. Our inclusive environment fosters teamwork and the sharing of knowledge, creating a space where fresh and innovative ideas flourish.",
    pic : Culture,
    },
  {
    title: "Flexibility & Freedom",
    description:
      "Experience the liberty of remote work with adaptable schedules and independence. We empower our developers to strike a balance between work and personal life while consistently delivering exceptional solutions.",
   pic: flexible,
    },
  {
    title: "Continuous Growth",
    description:
    "Unleash your potential through ongoing learning and opportunities for growth. Our dedication to professional advancement ensures you're always ahead in technological trends, propelling your career forward.",
  pic: continuous,
  },
];

export default function Core() {
  return (
    <div className="core-bg">
      <div className="core-container">
        <div className="heading">
        <h6 className="rea">Our Commitment</h6>
        <h2 className="core-head">Over core values and Benefits</h2>
        <p>
        Our foundation rests on values that cultivate a collaborative and supportive environment. We prioritize transparency, nurturing a culture where every team member can thrive. Join us and enjoy a range of benefits, from flexible work arrangements to continuous growth opportunities, all aimed at ensuring your success within our remote developer community
        </p></div>
        <div className="core-card">
          <Grid container spacing={3} sx={{ paddingLeft: 3, paddingRight: 3 }}>
            {coreValues.map((value, index) => (
              <Grid key={index} item xs={12} md={4}>
                <div className="grid-item-box">
                   <img src={value.pic} alt="abc"/>
                  <h3 className="core-center">{value.title}</h3>
                  <p className="pra-center">{value.description}</p>
                </div>
              </Grid>
            ))}
          </Grid>
        </div>
      </div>
    </div>
  );
}
