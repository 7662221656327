import React from "react";
import { Grid, Link, Divider } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
// import TelegramIcon from "@mui/icons-material/Telegram";
// import CopyrightIcon from "@mui/icons-material/Copyright";
import logo from "./images/Logo.png";
import InstagramIcon from "@mui/icons-material/Instagram";

export default function Footer() {
  const socialMediaLinks = [
    {
      icon: <FacebookIcon />,
      href: "https://www.facebook.com/destinoinfotech2022",
    },
    {
      icon: <TwitterIcon />,
      href: "https://twitter.com/destinoinfotec1",
    },
    {
      icon: <LinkedInIcon />,
      href: "https://www.linkedin.com/company/destino-infotech/",
    },
    {
      icon: <InstagramIcon />,
      href: "https://www.instagram.com/destino_infotech2021/",
    },
  ];

  const pageLinks = ["Features", "Pricing", "Blog Post", "Help Center"];
  const pagesLink = ["About", " Blog", "Costumer Reviews", "Contact"];

  const utilityLinks = ["Login", "Sign Up", "Reset Password", "404 Not Found"];

  return (
    <div className="footer">
      <div className="container">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <img className="foot-img" src={logo} alt="logo" />
            <p className="foot-p">
            Destino, an innovative marketing agency, offers comprehensive 360-degree solutions for hiring remote developers. Access our curated pool of skilled professionals vetted for excellence in diverse technologies. Elevate your projects with our tailored, hassle-free developer-hiring services.
            </p>
            <div className="icon-color">
              {socialMediaLinks.map((link, index) => (
                <Link
                  key={index}
                  href={link.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{ mb: 1 }}
                >
                  <div className="fot-li">{link.icon}</div>
                </Link>
              ))}
            </div>
          </Grid>

          <Grid item xs={6} sm={2}>
            <h3 className="foot-last">Page</h3>
            <div className="foot-link">
              {pageLinks.map((text, index) => (
                <Link
                  key={index}
                  href="#"
                  underline="none"
                  color="black"
                  sx={{ mb: 1 }}
                >
                  {text}
                </Link>
              ))}
            </div>
          </Grid>
          <Grid item xs={6} sm={2}>
            <div className="top-m">
              <div className="foot-link">
                {pagesLink.map((text, index) => (
                  <Link
                    key={index}
                    href="#"
                    underline="none"
                    color="black"
                    sx={{ mb: 1 }}
                  >
                    {text}
                  </Link>
                ))}
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={2}>
            <h3 className="foot-last">Utility Pages</h3>
            <div className="foot-link">
              {utilityLinks.map((text, index) => (
                <Link
                  key={index}
                  href="#"
                  underline="none"
                  color="black"
                  sx={{ mb: 1 }}
                >
                  {text}
                </Link>
              ))}
            </div>
          </Grid>
        </Grid>
      </div>

      <div className="container">
        <Divider variant="middle" className="hr" />
        <div className="copy">
          <p className="mr">
            &#169; 2011-2023 Destino Infotech Pvt. Ltd. All Rights Reserved
          </p>
          <div className="di">
            <Link href="#" underline="none" color="black">
              All Rights
            </Link>{" "}
            |
            <Link href="#" underline="none" color="black">
              Terms and Conditions
            </Link>
            |
            <Link href="#" underline="none" color="black">
              Privacy Policy
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
