import React from "react";
import Grid from "@mui/material/Grid";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import CommentBankOutlinedIcon from "@mui/icons-material/CommentBankOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import Section from "./images/section-cover-frame.png";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import BurstModeOutlinedIcon from "@mui/icons-material/BurstModeOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
// import Lottie from "lottie-react";
// import Phoneimg from "./images/mobilephone.json";

export default function Hire() {
  return (
    <div className="Remote-devloper">
      <div className="container">
        <div className="heading">
          <h6 className="rea">
            Grow your business with our platform's prowess in
          </h6>
          <h2 className="head">Why Hire Remote Developers</h2>
          <p className="pra3">
            Enabling website-building tools for realizing your digital dreams
            effortlessly, integrating multiple apps onto a single business
            platform for streamlined operations, and facilitating an easy setup
            for end-to-end business management encompassing sales, marketing,
            and customer support.
          </p>
        </div>

        <div className="cards">
          <Grid container spacing={2} justify="flex-start">
            <Grid
              item
              xs={12}
              md={4}
              order={{ xs: 2, md: 1 }}
              className="cards-main"
            >
              <FeatureCard
                icon={<TrendingUpIcon />}
                title="Custom analytics"
                description="Empower yourself to make informed decisions by staying connected with your team, regardless of your location. Benefit from real-time insights to drive quick and effective strategies."
              />
              <FeatureCard
                icon={<CommentBankOutlinedIcon />}
                title="Measure your performance"
                description="Effortlessly track and manage customer interactions and project progress using our integrated calendar. Stay on top of crucial events and milestones for enhanced performance assessment."
              />
              <FeatureCard
                icon={<GroupOutlinedIcon />}
                title="Team Management"
                description="Access a comprehensive sales dashboard in the cloud, enabling you to visualize team activity, revenue streams, and social metrics, facilitating effective team management and performance monitoring."
              />
            </Grid>
            <Grid item xs={12} md={4} order={{ xs: 1, md: 2 }}>
              <img className="img" src={Section} alt="iphone" />
              {/* <Lottie animationData={Phoneimg} /> */}
 
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              order={{ xs: 2, md: 3 }}
              className="cards-main"
            >
              <FeatureCard
                icon={<DriveFileRenameOutlineOutlinedIcon />}
                title="Build your website"
                description="Utilize our intuitive tool to construct your dream website hassle-free, even if you lack expertise in web design. Create a professional online presence tailored to your preferences effortlessly."
              />
              <FeatureCard
                icon={<BurstModeOutlinedIcon />}
                title="Connect multiple apps"
                description="Seamlessly integrate various applications onto a unified business platform. Consolidate your products in one place for streamlined operations and enhanced productivity."
              />
              <FeatureCard
                icon={<SettingsOutlinedIcon />}
                title="Easy setup"
                description="Experience an end-to-end business platform encompassing sales management, marketing automation, and help desk functionalities, all with a user-friendly setup for swift implementation."
              />
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}

function FeatureCard({ icon, title, description }) {
  return (
    <div className="card">
      {icon}
      <h4>{title}</h4>
      <p>{description}</p>
    </div>
  );
}
