import React from "react";
import { Grid } from "@mui/material";

export default function Techd() {
  return (
    <div>
      <div className="degree-main">
        <div className="degree-container">
          <h6 className="rea">Popular React.js Pairings</h6>
          <h2>We Offer the Most Popular React.js Combinations</h2>
          <p className="pe">
            Explore our array of popular React.js combinations that elevate your
            project's potential. From React Native for versatile mobile
            solutions to Redux for state management precision, our expertise
            extends to crafting seamless integrations with GraphQL, TypeScript,
            or Next.js. Embrace the power of these combinations tailored to
            optimize performance, scalability, and user experience.
          </p>
          <div className="degree-card">
            <Grid
              container
              spacing={3}
              sx={{ paddingLeft: 3, paddingRight: 3 }}
            >
              <Grid item xs={12} sm={12} md={6}>
                <div className="techd  ">
                  <h3 className="degree-center techa">
                    <span className="dot" />
                    ReactJs+Node
                  </h3>
                  <p className="degree-pra left">
                    ReactJS combined with Node.js forms a potent duo,
                    harmonizing front-end and back-end development seamlessly.
                    Leverage the robustness of React on the front end while
                    harnessing Node.js's event-driven architecture for scalable
                    and efficient server-side operations.
                  </p>
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <div className="techd ">
                  <h3 className="degree-center techa">
                    {" "}
                    <span className="dot" />
                    React Js+ PHP
                  </h3>
                  <p className="degree-pra left">
                    Pairing ReactJS with PHP unlocks a dynamic synergy, blending
                    React's interactive front-end capabilities with PHP's robust
                    server-side functionalities. Seamlessly integrate
                    user-centric interfaces with PHP's backend prowess for a
                    comprehensive, versatile web application solution.
                  </p>{" "}
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                {" "}
                <div className="techd">
                  <h3 className="degree-center techa">
                    {" "}
                    <span className="dot" />
                    React.js + Redux
                  </h3>
                  <p className="degree-pra left">
                    React.js combined with Redux offers a powerful state
                    management solution, ensuring streamlined data flow within
                    your application. Harness React's component-based
                    architecture in tandem with Redux's predictable state
                    management for efficient, scalable, and maintainable web
                    applications.
                  </p>{" "}
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <div className="techd ">
                  <h3 className="degree-center techa">
                    {" "}
                    <span className="dot" />
                    React Js+ AWS
                  </h3>
                  <p className="degree-pra left">
                    Integrating ReactJS with AWS opens doors to scalable,
                    cloud-powered applications. Combine React's dynamic
                    interfaces with AWS's robust infrastructure and services for
                    secure, flexible, and high-performing web solutions,
                    tailored to meet diverse business needs.
                  </p>{" "}
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                {" "}
                <div className="techd ">
                  <h3 className="degree-center techa">
                    {" "}
                    <span className="dot" /> React + Docker
                  </h3>
                  <p className="degree-pra left">
                    Pairing React with Docker streamlines development by
                    containerizing applications. Utilize React's frontend
                    prowess within Docker's container ecosystem, ensuring
                    consistency, portability, and efficiency in deploying and
                    managing your web applications across diverse environments.
                  </p>{" "}
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                {" "}
                <div className="techd">
                  <h3 className="degree-center techa">
                    {" "}
                    <span className="dot" />
                    React+ .Net
                  </h3>
                  <p className="degree-pra left">
                    Combining React with .NET unites robust frontend
                    capabilities with .NET's powerful backend framework.
                    Leverage React's dynamic interfaces alongside .NET's
                    scalable, secure, and versatile backend functionalities for
                    comprehensive, enterprise-grade web applications
                    .
                  </p>{" "}
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
}
