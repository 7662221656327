import React from "react";
import { Grid } from "@mui/material";
import {
  Language as LanguageIcon,
  ImportContacts as ImportContactsIcon,
  TextFields as TextFieldsIcon,
  MessageRounded as MessageRoundedIcon,
  TextSnippetSharp as TextSnippetSharpIcon,
  AccessTimeFilledSharp as AccessTimeFilledSharpIcon,
} from "@mui/icons-material";

export default function Development() {
  const developmentItems = [
    {
      icon: <LanguageIcon />,
      text: "Web Development: Crafting bespoke websites tailored to your vision.",
    },
    {
      icon: <ImportContactsIcon />,
      text: "App Development: Creating innovative and user-friendly applications.",
    },
    {
      icon: <TextFieldsIcon />,
      text: "App Development: Creating innovative and user-friendly applications.",
    },
    {
      icon: <MessageRoundedIcon />,
      text: "41 real conversations to give you fluency in learning languages.",
    },
    {
      icon: <TextSnippetSharpIcon />,
      text: "Grammar features & conjugation tables to shortcut your progress.",
    },
    {
      icon: <AccessTimeFilledSharpIcon />,
      text: "More than 1000 language combinations so you can learn.",
    },
  ];

  return (
    <div className="bg3">
      <div className="container">
        <div className="heading">
          <h6 className="rea">DEVELOPERS</h6>
          <h2 className="head">
            Development Services Our
            <br /> Remote Experts Can Provide
          </h2>
          <p>
            Our remote experts offer a range of development services, including
          </p>
        </div>
        <div className="section-nk">
          <Grid container spacing={2}>
            {developmentItems.map((item, index) => (
              <Grid key={index} item xs={12} sm={4} md={4}>
                <div className="nk-card">
                  {item.icon}
                  <p>{item.text}</p>
                </div>
              </Grid>
            ))}
          </Grid>
        </div>
      </div>
    </div>
  );
}
