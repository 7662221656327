import React from "react";
import "./Technology.scss";
import { Grid } from "@mui/material";
import icon1 from "./T-img/icon1.png";
import icon2 from "./T-img/icon2.png";
import icon3 from "./T-img/icon3.png";
import icon4 from "./T-img/icon4.png";
import icon5 from "./T-img/icon5.png";
import icon6 from "./T-img/icon6.png";

export default function Techb() {
  return (
    <div className="degree-main cl">
      <div className="degree-container">
        <h6 className="rea">Remote React JS Experts for Agile Scaling</h6>
        <h2>
          Acquire remote React JS developers swiftly to scale your projects on
          demand
        </h2>
        <p className="pe">
          Empower your endeavors with agile expertise and flexible scalability.
          Seamlessly integrate these skilled professionals into your team,
          ensuring efficiency, flexibility, and accelerated project scaling,
          irrespective of geographical barriers
        </p>
        <div className="degree-card reed">
          <Grid container spacing={3} sx={{ paddingLeft: 3, paddingRight: 3 }}>
            <Grid item xs={12} sm={12} md={4}>
              <div className="grid-item-box ">
                <h3 className="degree-center tech">
                  <img src={icon1} alt="" />
                  Frontend Development with React JS
                </h3>
                <p className="degree-pra">
                  Leverage React JS for immersive user experiences through
                  dynamic components and optimized performance. Our experts
                  build scalable, responsive interfaces, prioritizing seamless
                  integration and user-centric design.
                </p>
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <div className="grid-item-box ">
                <h3 className="degree-center tech">
                  <img src={icon2} alt={""} />
                  Plugin & API Integrations with React JS
                </h3>
                <p className="degree-pra">
                  Enhance functionality and versatility with React JS Plugin &
                  API integrations. Our team seamlessly integrates APIs,
                  creating efficient, scalable plugins that elevate user
                  experiences and expand application capabilities.
                </p>{" "}
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              {" "}
              <div className="grid-item-box ">
                <h3 className="degree-center tech">
                  {" "}
                  <img src={icon3} alt={""} />
                  Migration and Upgrade Services for React JS
                </h3>
                <p className="degree-pra">
                  Experience seamless React JS Migration and Upgrade services
                  for enhanced performance and modernization. Our team ensures
                  smooth transitions, leveraging best practices to upgrade
                  legacy systems, optimize code, and unlock the full potential
                  of React JS advancements.
                </p>{" "}
              </div>
            </Grid>
          </Grid>
        </div>
        <div className="degree-card">
          <Grid container spacing={3} sx={{ paddingLeft: 3, paddingRight: 3 }}>
            <Grid item xs={12} sm={12} md={4}>
              <div className="grid-item-box ">
                <h3 className="degree-center tech ">
                  {" "}
                  <img src={icon4} alt={""} />
                  Customized React Web Development
                </h3>
                <p className="degree-pra">
                  Tailored React web development aligning with your vision and
                  requirements. Our team leverages React's flexibility and
                  scalability to deliver dynamic, high-performance web
                  applications that resonate with your brand and users.
                </p>
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <div className="grid-item-box ">
                <h3 className="degree-center tech">
                  {" "}
                  <img src={icon5} alt={""} />
                  Consultation for React JS
                </h3>
                <p className="degree-pra">
                  Expert React JS Consultants offer strategic insights and
                  tailored solutions. Our professionals guide you from
                  architecture to optimization, ensuring efficient, scalable,
                  and innovative React JS implementations aligned with your
                  objectives.
                </p>{" "}
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              {" "}
              <div className="grid-item-box ">
                <h3 className="degree-center tech">
                  {" "}
                  <img src={icon6} alt={""} />
                  Support and Maintenance for React JS
                </h3>
                <p className="degree-pra">
                  Reliable support and maintenance ensure continual excellence
                  for your React JS applications. Our dedicated team provides
                  proactive support, timely updates, and meticulous maintenance
                  for sustained performance and user satisfaction.
                </p>{" "}
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}
