import { useState } from "react";
import Header from "../Home/Header";
import { Button, Drawer, Grid } from "@mui/material";
import "./Contact.scss";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from '@mui/icons-material/Email';
import BusinessIcon from '@mui/icons-material/Business';

import TextField from "@mui/material/TextField";
import Footer from "../Home/Footer";

import PhoneInput from "react-phone-number-input";


import "react-phone-number-input/style.css";
import Search from "../Home/Search";

export default function Contact() {
  // const [value, setValue] = useState("");

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message,setMessage] =useState("");
  
  const [companyName, setCompanyName] = useState("");
  const [formErrors, setFormErrors] = useState({});

  const validateForm = () => {
    const errors = {};

    if (!firstName.trim()) {
      errors.firstName = "First name is required";
    }

    if (!lastName.trim()) {
      errors.lastName = "Last name is required";
    }

    // Email validation regex for basic email format checking
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email.trim() || !emailRegex.test(email)) {
      errors.email = "Valid email is required";
    }

    if (!companyName.trim()) {
      errors.companyName = "Company name is required";
    }

    setFormErrors(errors);

    return Object.keys(errors).length === 0;
  };



  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      // Proceed with form submission
      console.log("Form submitted!");
      // You can add your logic here to handle form submission
    } else {
      console.log("Form has errors. Please correct them.");
    }
  };
     
  const sendEmail = async(e)=>{
    e.preventDefault();
     const res = await fetch("http://localhost:8006/register",{
    method:"POST",
      headers:{
        "content-Type":"application/json"
      },body:JSON.stringify({
        firstName,
        lastName,
        companyName,
        phone,
        message,
        email
      })
      })
     console.log(res)
  }
  return (
    <div>
      <Header />
      <Drawer />
      <div className="Contact-main">
        <div className="Contact-container">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
            <h6 className="hed">Innovative 360° Digital Solutions</h6>
              <h1 className="co-head">
                Feel Free To Send Us A Message About Your Website Needs
              </h1>
              <p className="co-pra">
              At Destino Infotech, we're dedicated to pioneering cutting-edge digital solutions. Our team is passionate about crafting tailored expertise that turns your vision into reality. With a strong emphasis on innovation and client success, we're committed to shaping the future of technology.
              </p>
              <h3 className="co-h">Get In touch</h3>
              <h3 className="co-h">
                
                <div className="call-icon">
                  {" "}
                  <CallIcon />
                </div>
               <p> 2246065742</p>
              </h3>
              <h3 className="co-h">
              
                <div className="call-icon">
                  {" "}
                  <EmailIcon />
                </div>
                <p> info@destinoinfotech.com</p>
              </h3>
              <h3 className="co-h">
          
                <div className="call-icon">
                  {" "}
                  <BusinessIcon />
                </div>
               <p> 05, Aditya Industrial Estate, Mindspace, Malad west, Mumbai 400064.</p>
              </h3>
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <div className="co-form">
                <form   onSubmit={handleSubmit}>
                  <TextField
                    label="Name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    error={formErrors.firstName}
                    helperText={formErrors.firstName}
                    sx={{ mb: 2, width: "100%" }}
                    className="form-input"
                  />
                  <TextField
                    label="Last Name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    error={formErrors.lastName}
                    helperText={formErrors.lastName}
                    sx={{ mb: 2, width: "100%" }}
                    className="form-input"
                  />
                  <TextField
                    label="Your Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    error={formErrors.email}
                    helperText={formErrors.email}
                    sx={{ mb: 2, width: "100%" }}
                    className="form-input"
                  />
                  {/* Add validation for phone input if required */}
                  <PhoneInput
                    label="Enter phone number"
                    id="outlined-start-adornment"
                    value={phone}                    
                    onChange={setPhone}
                    sx={{ mb: 2, width: "100%" }}
                  />
                  <TextField
                    label="Company Name"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                    error={formErrors.companyName}
                    helperText={formErrors.companyName}
                    sx={{ mb: 2, width: "100%" }}
                    className="form-input"
                  />
                  {/* Other form fields */}
                  <TextField
                    id="outlined-multiline-static"
                    label="Message"
                    value={message}                    
                    onChange={(e) => setMessage(e.target.value)}
                    multiline
                    rows={4}
                    sx={{ mb: 2, width: "100%" }}
                    className="form-input"
                  />
                  <Button
                    type="submit"
                    className="btn-def "
                    variant="contained"
                    sx={{ width: "100%" }}
                    onClick={sendEmail}
                  >
                    Send Message
                  </Button>
                </form>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Search />
      <Footer />
    </div>
  );
}
