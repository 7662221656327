import React from "react";
import Grid from "@mui/material/Grid";
import Man from "./images/d.png";
import TextField from "@mui/material/TextField";
// import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
// import { Margin } from "@mui/icons-material";

export default function Search() {
  return (
    <div className="back">
      <div className="container">
        <div className="new">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={8}>
              {" "}
              <h3 className="Se">Subscribe To Our Newsletter</h3>
              <p className="pe">
              We guarantee to get back to you within a business day.
              </p>
              <TextField
                sx={{ mt: 4 }}
                id="outlined-basic"
                label="Your Email..."
                variant="outlined"
              />
              <Button className="btn-def" variant="contained">
                {" "}
                Subscribe
              </Button>
            </Grid>
            <Grid item xs={12} sm={4}>
              <img className="static" src={Man} alt="" />
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}
