import React from 'react'
import Header from '../Home/Header'
// import Drawer  from '../Home/DrawerComp'
// import DrawerComp from '../Home/DrawerComp'
import { Button, Grid } from "@mui/material";

export default function Mobile() {
  return (
    <><Header/>
       <div className="ser-main">
          <div className="ser-container">
            <Grid
              container
              spacing={3}
              sx={{ paddingLeft: 3, paddingRight: 3 }}
            >
              <Grid item xs={12} sm={12} md={6}>
                <h1 className="ser-head">
                  Hire
                  <span className="color">
                    {" "}
                    Flutter <br />
                    Developers{" "}
                  </span>{" "}
                  Quickly
                </h1>
                <p className="ser-pra">
                  Work with a highly skilled and experienced team of React.Js
                  developers and accelerate your project development. Hire react
                  programmers on flexible models to create interactive UI & UX
                  that drive high user engagement.
                </p>

                <h5>Pre-vetted Experts</h5>
                <h5>7 Days Free Trial</h5>
                <h5>2X faster Delivery</h5>
                <h5>Agile Development Methodology</h5>
                <h5>Easy team scaling</h5>
                <Button className="btn-def">Talk To Our Expert</Button>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                {" "}
                <img src={""} alt={""} />
              </Grid>
            </Grid>
          </div>
        </div>
    </>
  )
}
