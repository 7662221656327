import React from "react";
import { Grid } from "@mui/material";
// import Button from "@mui/material/Button";
import Cover from "./images/section-cover-2.png";
import Model from "./images/section-cover-3.png";
import SEO from "./images/section-cover-4.png";

const hiringSteps = [
  {
    number: 1,
    title: "We Will Gather Your Empty Listed Product",
    description:
      "Entrust us with managing your inventory of empty listed products, ensuring efficiency for your business operations",
    image: Cover,
  },
  {
    number: 2,
    title: "We Will Try To Make A New Business Model",
    description:
      "Collaborate with our experts to craft and implement a tailored business model that aligns with your goals and aspirations.",
    image: Model,
  },
  {
    number: 3,
    title: "We Will Help You About Marketing And SEO",
    description:
      "Gain insights from our adept marketing and SEO team, enhancing your online visibility and attracting more customers to your business.",
    image: SEO,
  },
];

export default function Process() {
  return (<>
    <div className="process">
      <div className="container">
        <div className="heading">
          <h6 className="rea">PROCESS</h6>
          <h3 className="head">Process after Hiring</h3>
          <p className="pra3">
            Experience business growth by leveraging our proven strategies and
            expert guidance throughout your journey. Our process includes:
          </p>
        </div>
        <div className="pro">
          {hiringSteps.map((step, index) => (
            <Grid container spacing={2} key={index}>
              <Grid
                item
                xs={12}
                sm={6}
                order={{ xs: 2, sm: 1 }}
                className="step-counter"
              >
                <span className="step-number">{step.number}</span>
                <h4>{step.title}</h4>
                <p>{step.description}</p>
                {/* <Button sx={{ m: 2 }} variant="outlined">
                  Learn more
                </Button> */}
                 
              </Grid>

              {/* <Grid item xs={6} className={index % 2 === 0 ? "" : "text-right"}> */}
              <Grid
                item
                xs={12}
                sm={6}
                order={{ xs: 1, sm: 2 }}
                className="step-image"
              >
                <img
                  className={`img${index + 1}`}
                  src={step.image}
                  alt={step.title}
                />
               
              </Grid>
            </Grid>
          ))}
        </div>
      </div>
        {/* <Button className="btn-cen"sx={{ m: 2 }} variant="outlined">
                  Learn more
                </Button>  */}
    </div>
   
    </>
  );
}
