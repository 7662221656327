import React from "react";
import Header from "./Header";
import Hero from "./Hero";
import Remote from "./Remote";
import "./App.scss";
import Hire from "./Hire";
import Development from "./Development";
import Process from "./Process";
// import Advantage from "./Advantage";
import How from "./How";
import Search from "./Search";
import Footer from "./Footer";
import {Helmet} from "react-helmet";
export default function Home() {
  return (
    <div>
      <Helmet>
                <meta charSet="utf-8" />
                <title>Best Digital Marketing Agency | Remote Developers Services | Destino Infotech</title>
                <meta name="description" content="keting services, Destino is the place for you. Destino is a digital agency that focuses on internet marketing to cater to your needs."/>
                <link rel="canonical" href="http://mysite.com/example" />
            </Helmet>
      <Header />
      <Hero />
      <Remote />
      <Development />
      <Hire />
      <Process />
      {/* <Advantage /> */}
      <How />
      <Search />
      <Footer />
    </div>
  );
}
