import React from "react";
import Header from "../Home/Header";
import { Drawer, Grid } from "@mui/material";
import "./About.scss";
// import AcUnitIcon from "@mui/icons-material/AcUnit";
import Ban from "./img/banner-right-image.png";

export default function Abouts() {
  return (
    <div>
      <Header />
      <Drawer />
      <div className="about-main">
        <div className="About-container">
          <Grid container spacing={2} className="about-center">
            <Grid item xs={12} md={6}>
              <h6 className="hed">Driving Excellence to Transform Visions into Reality</h6>
              <h1 className="about-hed">Innovative 360° Digital Solutions</h1>
              <p className="pra">
                At Destino Infotech, we pave the way for innovation,
                specializing in crafting exceptional digital solutions. Our team
                is driven by a passion for excellence, delivering tailored
                expertise to transform your vision into reality. With a focus on
                innovation and client success, we're dedicated to shaping the
                future of technology
              </p>
            </Grid>
            <Grid item xs={12} md={6}>
              <img className="img1" src={Ban} alt="" />
            </Grid>
          </Grid>

          {/* <div className="about-icon">
            <Grid container spacing={2}>
              <Grid item xs={6} sm={3}>
                <div className="i1">
                  <AcUnitIcon />
                  <h4>Lorem, ipsum dolor.</h4>
                </div>
              </Grid>
              <Grid item xs={6} sm={3}>
                <div className="i1">
                  <AcUnitIcon />
                  <h4>Lorem, ipsum dolor.</h4>
                </div>
              </Grid>
              <Grid item xs={6} sm={3}>
                <div className="i1">
                  <AcUnitIcon />
                  <h4>Lorem, ipsum dolor.</h4>
                </div>
              </Grid>
              <Grid item xs={6} sm={3}>
                <div className="i1">
                  <AcUnitIcon />
                  <h4>Lorem, ipsum dolor.</h4>
                </div>
              </Grid>
            </Grid>
          </div> */}
        </div>
      </div>
    </div>
  );
}
