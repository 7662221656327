// import { AppBar } from "@mui/material";
import React  from "react";
import Header from "../Home/Header";
import { Drawer, Grid } from "@mui/material";
import "./Services.scss";
import Simg from "./S-Img/Banner Image - Hire Developeropt.png";


import FormButton from "../Home/FormButton"

export default function Service() {
 
  return (
    <>
      <div>
        <Header />
        <Drawer />
        <div className="ser-main">
          <div className="ser-container">
            <Grid
              container
              spacing={3}
              sx={{ paddingLeft: 3, paddingRight: 3 }}
            >
              <Grid item xs={12} sm={12} md={6}>
                <h1 className="ser-head">
                  Hire<span className="color"> Developers in India </span>
                </h1>
                <p className="ser-pra">
                  Hire the best Indian developers from a vast pool of pre-vetted
                  talent with flexible working models to meet your project
                  requirements.
                </p>
                <h4>
                  Benefits of Hiring Dedicated Remote Developers from Destino
                  Infotech:
                </h4>
                <h5>Flexible Hiring- Part-time, Hourly and Monthly Basis</h5>
                <h5>Strict NDA Terms + 2X Faster Delivery</h5>
                <h5>200+ Pre-vetted & Certified Programmer</h5>
                <FormButton/>              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                {" "}
                <img src={Simg} alt={""} />
                {/* <Lottie className="lotImg" animationData={bannerImg} /> */}
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
}
