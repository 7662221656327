import React from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
// import full from "./images/imgpsh_fullsize_anim.png";
import { Grid } from "@mui/material";
import Lottie from "lottie-react";
import HeroImg from "./images/hero.json";
import { Link } from 'react-router-dom';

export default function Hero() {
  return (
    <div className="section">
      <div className="container">
        <Grid container spacing={2}>
          <div className="hero">
            <Grid container item xs={12} sm={12} md={6}>
              <h6 className="hed">Welcome to Destino Infotech</h6>
              <h1 className="hero-hed">YOUR HUB FOR REMOTE WEB DEVELOPERS.</h1>
              <p className=" pra">
                Looking to boost your projects with top-tier remote web
                developers? At Destino Infotech, we connect you with skilled
                professionals ready to transform your digital ambitions into
                reality.
              </p>
              <Stack
                sx={{ marginTop: "60px", marginBottom: "60px" }}
                spacing={2}
                direction="row"
              >
               <Link to="/contact">  <Button className="btn-def" variant="contained">
                  Get started
                </Button></Link>
               <Link to ="/service"> <Button className="le" variant="text">
                  Learn more
                </Button></Link>
              </Stack>

            
            </Grid>
            <Grid container item xs={12} sm={12} md={6}>
              <div className="Hero-animation">
                <Lottie animationData={HeroImg} />
              </div>
            </Grid>
          </div>
        </Grid>
      </div>
    </div>
  );
}
