// import React from 'react'
import React from "react";
// import "./Technology.scss";
import { Grid } from "@mui/material";
// import icon1 from "./T-img/icon1.png";
// import icon2 from "./T-img/icon2.png";
// import icon3 from "./T-img/icon3.png";
// import icon4 from "./T-img/icon4.png";
// import icon5 from "./T-img/icon5.png";
// import icon6 from "./T-img/icon6.png";
import angicon1 from "./AngImg/angularjs-icons-01.png";
import angicon2 from "./AngImg/angularjs-icons-02.png"
import angicon3 from "./AngImg/angularjs-icons-03.png";
import angicon4 from "./AngImg/angularjs-icons-04.png";
import angicon5 from "./AngImg/angularjs-icons-05.png";
import angicon6 from "./AngImg/angularjs-icons-06.png";

export default function AngB() {
  return (
    <div className="degree-main cl">
      <div className="degree-container">
        <h6 className="rea">Remote React JS Experts for Agile Scaling</h6>
        <h2>Proficiency of Our Angular Developers</h2>
        <p className="pe">
          Our dedicated Angular developers specialize in delivering a diverse
          array of Angular development services. Catering to a broad client base
          and crafting robust solutions using the latest frameworks
        </p>
        <div className="degree-card reed">
          <Grid container spacing={3} sx={{ paddingLeft: 3, paddingRight: 3 }}>
            <Grid item xs={12} sm={12} md={4}>
              <div className="grid-item-box ">
                <h3 className="degree-center tech">
                  <img src={angicon1} alt="" />
                  Angular Framework Expertise
                </h3>
                <p className="degree-pra">
                  Our expert AngularJS developers possess in-depth knowledge of
                  the Angular framework, leveraging features like two-way data
                  binding, dependency injection, and modular architecture. This
                  proficiency enables the creation of scalable, maintainable
                  applications with robust functionalities.
                </p>
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <div className="grid-item-box ">
                <h3 className="degree-center tech">
                  <img src={angicon2} alt={""} />
                  Component-Based Architecture
                </h3>
                <p className="degree-pra">
                  Utilizing Angular's framework, our AngularJS experts adeptly
                  construct efficient and modular web applications. Their
                  skillful implementation of a component-based architecture
                  results in clean, scalable, and maintainable structures,
                  fostering the development of reusable and encapsulated
                  components for optimized practices.
                </p>{" "}
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              {" "}
              <div className="grid-item-box ">
                <h3 className="degree-center tech">
                  {" "}
                  <img src={angicon3} alt={""} />
                  RxJS and Observables Mastery
                </h3>
                <p className="degree-pra">
                  With a strong command of RxJS and Observables, our Angular
                  developers significantly enhance application performance.
                  Their expertise in reactive programming empowers applications
                  with smooth interaction and effective data management, leading
                  to heightened responsiveness in Angular apps.
                </p>{" "}
              </div>
            </Grid>
          </Grid>
        </div>
        <div className="degree-card">
          <Grid container spacing={3} sx={{ paddingLeft: 3, paddingRight: 3 }}>
            <Grid item xs={12} sm={12} md={4}>
              <div className="grid-item-box ">
                <h3 className="degree-center tech ">
                  {" "}
                  <img src={angicon4} alt={""} />
                  Advanced Routing and Navigation Capabilities
                </h3>
                <p className="degree-pra">
                  Our developers possess advanced skills in routing and
                  navigation within Angular. This expertise enables them to
                  orchestrate complex navigation flows seamlessly, leveraging
                  Angular's capabilities to craft dynamic and user-friendly web
                  applications with intricate navigation structures.
                </p>
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <div className="grid-item-box ">
                <h3 className="degree-center tech">
                  {" "}
                  <img src={angicon5} alt={""} />
                  Complete Code Security and RESTful API Integration
                </h3>
                <p className="degree-pra">
                  The team excels in integrating RESTful APIs, optimizing data
                  exchange, and fortifying application security. Proficient in
                  handling HTTP requests, they enhance app functionality and
                  connectivity while ensuring robust, data-driven solutions.
                </p>{" "}
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              {" "}
              <div className="grid-item-box ">
                <h3 className="degree-center tech">
                  {" "}
                  <img src={angicon6} alt={""} />
                  Testing Proficiency
                </h3>
                <p className="degree-pra">
                  Proficient in unit, end-to-end, and behavior-driven
                  development, our AngularJs developers employ top testing tools
                  such as Protractor, Jasmine, Karma, and more. Through rigorous
                  testing practices, they ensure the delivery of robust,
                  error-free applications, enhancing overall reliability.
                </p>{" "}
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}
