import React from "react";
import "./About.scss";
import { Grid } from "@mui/material";
// import Leftimg from "./img/about-left-image.png";
// import BedroomChildIcon from "@mui/icons-material/BedroomChild";
import Icon1 from "./img/service-icon-01.png";
import Icon2 from "./img/service-icon-02.png";
import Icon3 from "./img/service-icon-03.png";
import img33 from "./img/Why choose us_Creative.png";

export default function Choose() {
  return (
    <div className="choose-main">
      <div className="choose-container">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={4} order={{ xs: 3, sm: 3, md: 2 }}>
            <img className="img2" src={img33} alt="" />
          </Grid>
          <Grid item xs={8} order={{ xs: 2, sm: 2, md: 3 }}>
            <div className="choose-head">
              <h2>Why Choose Us</h2>
              <div className="all-cho">
                <div className="all-cho-inner">
                  <div className="cho-icon">
                    <img src={Icon1} alt="" />{" "}
                  </div>
                  <div className="icon-head">
                    <h3>Expertise in Innovation</h3>
                    <p>
                    We specialize in innovative solutions, utilizing our team's expertise to achieve success.

.
                    </p>
                  </div>
                </div>
                <div className="all-cho-inner">
                  <div className="cho-icon">
                    {" "}
                    <img src={Icon2} alt="" />{" "}
                  </div>
                  <div className="icon-head">
                    <h3>Client-Centric Approach</h3>
                    <p>
                    Committed to fulfilling your requirements, ensuring a customer-oriented journey.
                    </p>
                  </div>
                </div>
              </div>
              <div className="choose-card">
                <div className="all-cho-inne">
                  <div className="cho-icon">
                    {" "}
                    <img src={Icon3} alt="" />{" "}
                  </div>
                  <div className="icon-head">
                    <h3>Proven Track Record</h3>
                    <p>
                    Evidenced success through consistently delivering high-quality outcomes.
                    </p>
                  </div>
                </div>
                <div className="all-cho-inne">
                  <div className="cho-icon">
                    {" "}
                    <img src={Icon1} alt="" />{" "}
                  </div>
                  <div className="icon-head">
                    <h3>Transparent Collaboration</h3>
                    <p>
                    Our belief in open communication fosters clear and transparent collaborations.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
