import {React, useState} from 'react';
import PhoneInput from "react-phone-number-input";
import TextField from "@mui/material/TextField";
import {
  Dialog, DialogTitle, DialogContent, DialogActions
} from "@mui/material";
// import { useState } from "react";
import {Button} from "@mui/material"

export default function FormButton() {
    const [val, setVal] = useState("");
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [formErrors, setFormErrors] = useState({});
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const validateForm = () => {
    const errors = {};

    if (!firstName.trim()) {
      errors.firstName = 'First name is required';
    }

    if (!lastName.trim()) {
      errors.lastName = 'Last name is required';
    }

    // Email validation regex for basic email format checking
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email.trim() || !emailRegex.test(email)) {
      errors.email = 'Valid email is required';
    }

    if (!companyName.trim()) {
      errors.companyName = 'Company name is required';
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();



    if (validateForm()) {
      // Proceed with form submission
      console.log('Form submitted!');
      // You can add your logic here to handle form submission
      console.log('Current Page URL:', window.location);
      console.log('Current Page URL:', window.location.href);
    } else {
      console.log('Form has errors. Please correct them.');
    }
  };

  const [openDialog, setOpenDialog] = useState(false);

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  return (
    <div><Button
    className="btn-def"
    variant="contained"
    onClick={handleDialogOpen}
  >
    Talk to Our Expert
  </Button>
  <Dialog open={openDialog} onClose={handleCloseDialog}>
<DialogActions className="letheader">
<DialogTitle >Let's Talk</DialogTitle>
    <Button onClick={handleCloseDialog}>X</Button>
 
</DialogActions>
  <DialogContent> 

    <div className="pop-form">
      <form onSubmit={handleSubmit}>
        <TextField
          label="Name"
          value={firstName}

          onChange={(e) => setFirstName(e.target.value)}
          error={formErrors.firstName}
          helperText={formErrors.firstName}
          sx={{ mb: 2, width: '100%' }}
          className="form-input"
        />
        <TextField
          label="Last Name"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          error={formErrors.lastName}
          helperText={formErrors.lastName}
          sx={{ mb: 2, width: '100%' }}
          className="form-input"
        />
        <TextField
          label="Your Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          error={formErrors.email}
          helperText={formErrors.email}
          sx={{ mb: 2, width: '100%' }}
          className="form-input"
        />
        {/* Add validation for phone input if required */}
        <PhoneInput
          label="Enter phone number"
          id="outlined-start-adornment"

          value={val}
          onChange={setVal}

          className="customPhoneInput"
        />
        <TextField
          label="Company Name"
          value={companyName}
          onChange={(e) => setCompanyName(e.target.value)}
          error={formErrors.companyName}
          helperText={formErrors.companyName}
          sx={{ mb: 2, width: '100%' }}
          className="form-input"
        />
        {/* Other form fields */}
        <TextField
          id="outlined-multiline-static"
          label="Message"
          multiline
          rows={3}
          sx={{ mb: 2, width: "100%" }}
          className="form-input"
        />
        <Button
          type="submit"
          className="btn-def pop-btn"
          variant="contained"
          sx={{ width: '100%' }}
        >
          Send Message
        </Button>
      </form>
    </div>

  </DialogContent>
 
</Dialog></div>
  )
}
