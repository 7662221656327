import React, { useState } from "react";
import {
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  // Typography,
} from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const PAGES = [
  { name: "Home", route: "/" },
  { name: "Services", route: "/service" },
  { name: "Technologies", route: "/" },
  { name: "Contact us", route: "/contact" },
  { name: "About", route: "/about" },
];

const DrawerComp = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [technologiesAccordionOpen, setTechnologiesAccordionOpen] = useState(
    false
  );
  const Navigate = useNavigate();

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setTechnologiesAccordionOpen(isExpanded ? panel : false);
  };

  const handleMenuItemClick = (route) => {
    setOpenDrawer(false);
    Navigate(route);
  };

  return (
    <React.Fragment>
      <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)}>
        <List sx={{ width: "70vw" }}>
          {PAGES.map((page, index) => (
            <React.Fragment key={index}>
              {page.name !== "Technologies" ? (
                <ListItemButton
                  onClick={() => {
                    setOpenDrawer(false);
                    Navigate(page.route);
                  }}
                >
                  <ListItemIcon>
                    <ListItemText primary={page.name} />
                  </ListItemIcon>
                </ListItemButton>
              ) : (
                <Accordion
                  expanded={technologiesAccordionOpen === "panel1"}
                  onChange={handleAccordionChange("panel1")}
                >
                  <AccordionSummary
                    expandIcon={
                      technologiesAccordionOpen ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )
                    }
                  >
                    <ListItemText primary={page.name} />
                  </AccordionSummary>
                  <AccordionDetails>
                    <List>
                      {/* <ListItemButton
                        onClick={() => handleMenuItemClick("")}
                      >
                     <span className="ile">   <ListItemText primary="Front end" /></span>
                      </ListItemButton> */}
                       <ListItemButton
                        onClick={() => handleMenuItemClick("/React")}
                      >
                   <ListItemText primary="React Js" /> 
                      </ListItemButton>
                      <ListItemButton
                        onClick={() => handleMenuItemClick("/angular")}
                      >
                        <ListItemText primary="Angular" />
                      </ListItemButton>
                     <ListItemButton
                        onClick={() => handleMenuItemClick("/Vue")}
                      >
                        <ListItemText primary="Vue Js" />
                      </ListItemButton>
                    {/*  <ListItemButton
                        onClick={() => handleMenuItemClick("/technology")}
                      >
                        <span className="ile"> <ListItemText primary="FRONTEND" /></span>
                      </ListItemButton>
                      <ListItemButton
                        onClick={() => handleMenuItemClick("/technology")}
                      >
                        <ListItemText primary="JavaScript" />
                      </ListItemButton>
                      <ListItemButton
                        onClick={() => handleMenuItemClick("/technology")}
                      >
                        <ListItemText primary="Flutter For Web" />
                      </ListItemButton>
                      <ListItemButton
                        onClick={() => handleMenuItemClick("/technology")}
                      >
                        <ListItemText primary="Angular JS" />
                      </ListItemButton>
                      <ListItemButton
                        onClick={() => handleMenuItemClick("/technology")}
                      >
                        <ListItemText primary="React JS" />
                      </ListItemButton>
                      <ListItemButton
                        onClick={() => handleMenuItemClick("/technology")}
                      >
                        <ListItemText primary="Vue JS" />
                      </ListItemButton>
                      <ListItemButton
                        onClick={() => handleMenuItemClick("/technology")}
                      >
                        <ListItemText primary="Electron" />
                      </ListItemButton>
                      <ListItemButton
                        onClick={() => handleMenuItemClick("/technology")}
                      >
                       <span className="ile"> <ListItemText primary="BACKEND" /></span>
                      </ListItemButton>
                      <ListItemButton
                        onClick={() => handleMenuItemClick("/technology")}
                      >
                        <ListItemText primary="Node JS" />
                      </ListItemButton>
                      <ListItemButton
                        onClick={() => handleMenuItemClick("/technology")}
                      >
                        <ListItemText primary="Python" />
                      </ListItemButton>
                      <ListItemButton
                        onClick={() => handleMenuItemClick("/technology")}
                      >
                        <ListItemText primary="Java" />
                      </ListItemButton>
                      <ListItemButton
                        onClick={() => handleMenuItemClick("/technology")}
                      >
                        <ListItemText primary="PHP" />
                      </ListItemButton>
                      <ListItemButton
                        onClick={() => handleMenuItemClick("/technology")}
                      >
                        <ListItemText primary="Dot Net" />
                      </ListItemButton>
                      <ListItemButton
                        onClick={() => handleMenuItemClick("/technology")}
                      >
                        <span className="ile"> <ListItemText primary="FULL STACK" /></span>

                      </ListItemButton>
                      <ListItemButton
                        onClick={() => handleMenuItemClick("/technology")}
                      >
                        <ListItemText primary="MEAN" />
                      </ListItemButton>
                      <ListItemButton
                        onClick={() => handleMenuItemClick("/technology")}
                      >
                        <ListItemText primary="MERN" />
                      </ListItemButton> */}
                    </List>
                  </AccordionDetails>
                </Accordion>
              )}
            </React.Fragment>
          ))}
        </List>
      </Drawer>

      <IconButton
        sx={{ color: "black", marginLeft: "auto" }}
        onClick={() => setOpenDrawer(!openDrawer)}
      >
        <MenuIcon />
      </IconButton>
    </React.Fragment>
  );
};

export default DrawerComp;
