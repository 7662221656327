import React from "react";
import Service from "./Service";
import YourNext from "./YourNext";
import Degree from "./Degree";
// import ProcessOf from "./ProcessOf";
import Benefits from "./Benefits";
import ServiceFaq from "./ServiceFaq";
import Footer from "../Home/Footer"
import Search  from "../Home/Search";
import {Helmet} from "react-helmet"

export default function Servicem() {
  return (
    <div>
       <Helmet>
                <meta charSet="utf-8" />
                <title>Service | Destino Infotech</title>
                <meta name="description" content="Helmet application" />
                <link rel="canonical" href="http://mysite.com/example" />
            </Helmet>
      <Service />
      <YourNext />
      <Degree />
      {/* <ProcessOf /> */}
      <Benefits />
      <ServiceFaq />
      <Search/>
      <Footer />
    </div>
  );
}
