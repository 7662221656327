import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "./App.scss";
import { Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
// import Link from "@mui/material/Link";


import {
  AppBar,
  Button,
  Tab,
  Tabs,
  Toolbar,
  // Typography,
  useMediaQuery,
  useTheme,
  Dialog, DialogTitle, DialogContent, DialogActions
} from "@mui/material";
import DrawerComp from "./DrawerComp";
import logo from "./images/i.png";
import { useNavigate } from "react-router-dom";
import Model from "./Modal";
// import Mobile from "../TechnologyHover/Mobile"
const PAGES = [
  { name: "Home", route: "/" },
  { name: "Services", route: "/service" },
  { name: "Technologies", route: "#" },
  { name: "Contact us", route: "/contact" },
  { name: "About", route: "/about" },
];

const Header = ({ currentUrl }) => {
  const [value,setValue ] = useState(0);

  const [isTechnologiesHovered, setIsTechnologiesHovered] = useState(false);
  const [isModelVisible, setIsModelVisible] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  const location = useLocation();
  
  const calculateTechnologiesTabPosition = () => {
    const technologiesTab = document.getElementById("technologies-tab");
    if (technologiesTab) {
      const rect = technologiesTab.getBoundingClientRect();
      return {
        top: rect.bottom,
        left: rect.left,
      };
    }
    return { top: 0, left: 0 };
  };

  const handleTechnologiesTabEnter = () => {
    setIsModelVisible(true);
    setIsTechnologiesHovered(true);
  };

  const handleTechnologiesTabLeave = () => {
    setIsModelVisible(false);
    setIsTechnologiesHovered(false);
  };

  const handleModelEnter = () => {
    setIsModelVisible(true);
    setIsTechnologiesHovered(true);
  };

  const handleModelLeave = () => {
    setIsModelVisible(false);
    setIsTechnologiesHovered(false);
  };
  const [hasScrolled, setHasScrolled] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY > (window.innerHeight * 2) / 100; // 2% of window height
      setHasScrolled(scrolled);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  //dialog
  const [openDialog, setOpenDialog] = useState(false);

  const handleButtonClick = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  // form validitaion
  const [val, setVal] = useState("");
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [formErrors, setFormErrors] = useState({});

  const validateForm = () => {
    const errors = {};

    if (!firstName.trim()) {
      errors.firstName = 'First name is required';
    }

    if (!lastName.trim()) {
      errors.lastName = 'Last name is required';
    }

    // Email validation regex for basic email format checking
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email.trim() || !emailRegex.test(email)) {
      errors.email = 'Valid email is required';
    }

    if (!companyName.trim()) {
      errors.companyName = 'Company name is required';
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();



    if (validateForm()) {
      // Proceed with form submission
      console.log('Form submitted!');
      // You can add your logic here to handle form submission
      console.log('Current Page URL:', window.location);
      console.log('Current Page URL:', window.location.href);
    } else {
      console.log('Form has errors. Please correct them.');
    }
  };
  // Scroll to top when the route changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
//  const handleTabChange = (event, newValue) => {
//     console.log("Setting value to:", newValue);
//     setValue(newValue);
//   };

  // useEffect(() => {
  //   // This effect runs after the state has been updated
  //   navigate(PAGES[value].route);
  // }, [value]);
  
  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <AppBar
          sx={{
            background: "#F5F5DC",
            color: "black",
            boxShadow: hasScrolled
              ? "0px 2px 4px rgba(0,0,0,0.1)"
              : "0px 0px 0px rgba(0,0,0,0)",
          }}
        >
          <div className="container nav">
            <Toolbar className="p-m">
              <Grid item xs={3}>
             <Link to="/"> <img
                  src={logo}
                  alt="logo"
                  className="logo"
                  to={"/"}
                  style={{ width: "60px" }}
                /></Link>
              </Grid>
              {isMatch ? (
                <>
                  {/* <Typography></Typography> */}
                  <DrawerComp />
                </>
              ) : (
                <>
                  <Grid item xs={6}>
                    <Tabs
                       className="tabss"
                      //  value={value}
                      //  onChange={handleTabChange}
                       sx={{ marginLeft: "0px",fontWeightRegular: "400" }}
                     
                    >
                      {" "}
                      {PAGES.map((page, index) => (
                   
                        <Tab 
                          id={
                            page.name === "Technologies"
                              ? "technologies-tab"
                              : ""
                          }
                          onClick={() => { setValue(index); navigate(page.route); }}                       
                             key={index}
                        
                      
                          label={page.name}
                          className={
                            value === index && page.name === "Technologies"
                              ? isTechnologiesHovered
                                ? "active"
                                : ""
                              : ""
                          }
                          onMouseEnter={() =>
                            page.name === "Technologies" &&
                            handleTechnologiesTabEnter()
                          }
                          onMouseLeave={() =>
                            page.name === "Technologies" &&
                            handleTechnologiesTabLeave()
                          }
                        />
                      ))}
                    </Tabs>

                    {isTechnologiesHovered && (
                      <Model
                        onMouseEnter={handleModelEnter}
                        onMouseLeave={handleModelLeave}
                        id="technologies-dropdown"
                        position={calculateTechnologiesTabPosition()}
                        isModelVisible={isModelVisible}
                      />
                    )}
                  </Grid>
                  <Grid item xs={3}>
                    <Button
                      sx={{ marginLeft: "auto" }}
                      variant="contained"
                      className="btn-def but"
                      onClick={handleButtonClick}
                    >
                      Let's Talk
                    </Button>
                  </Grid>
                </>
              )}
            </Toolbar>
          </div>

        </AppBar>
      </Grid>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
      <DialogActions className="letheader">
      <DialogTitle >Let's Talk</DialogTitle>
          <Button onClick={handleCloseDialog}>X</Button>
       
      </DialogActions>
        <DialogContent> 

          <div className="pop-form">
            <form onSubmit={handleSubmit}>
              <TextField
                label="Name"
                value={firstName}

                onChange={(e) => setFirstName(e.target.value)}
                error={formErrors.firstName}
                helperText={formErrors.firstName}
                sx={{ mb: 2, width: '100%' }}
                className="form-input"
              />
              <TextField
                label="Last Name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                error={formErrors.lastName}
                helperText={formErrors.lastName}
                sx={{ mb: 2, width: '100%' }}
                className="form-input"
              />
              <TextField
                label="Your Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                error={formErrors.email}
                helperText={formErrors.email}
                sx={{ mb: 2, width: '100%' }}
                className="form-input"
              />
              {/* Add validation for phone input if required */}
              <PhoneInput
                label="Enter phone number"
                id="outlined-start-adornment"

                value={val}
                onChange={setVal}

                className="customPhoneInput"
              />
              <TextField
                label="Company Name"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                error={formErrors.companyName}
                helperText={formErrors.companyName}
                sx={{ mb: 2, width: '100%' }}
                className="form-input"
              />
              {/* Other form fields */}
              <TextField
                id="outlined-multiline-static"
                label="Message"
                multiline
                rows={3}
                sx={{ mb: 2, width: "100%" }}
                className="form-input"
              />
              <Button
                type="submit"
                className="btn-def pop-btn"
                variant="contained"
                sx={{ width: '100%' }}
              >
                Send Message
              </Button>
            </form>
          </div>

        </DialogContent>
       
      </Dialog>
    </React.Fragment>
  );
};
export default Header;
