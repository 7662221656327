import React from "react";
import "./Services.scss";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import Footer from "../Home/Footer";

const FaqItem = ({ question, answer }) => {
  return (
    <Accordion className="accor">
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>
          <h4>{question}</h4>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>{answer}</Typography>
      </AccordionDetails>
    </Accordion>
  );
};

const faqData = [
  {
    question: "How does Destino ensure the quality of remote developers?",
    answer:
      "Destino rigorously vets all developers, assessing their expertise, experience, and skills. We provide access to a pool of pre-screened professionals with proven track records, ensuring top-tier quality for your projects.",
  },
  {
    question: "Can I scale the team size if needed during the project?",
    answer:
    "Absolutely. Whether you need to scale up or down, Destino offers flexibility. You can easily adjust your team size based on project requirements, ensuring efficiency and agility in development.",
  },
  {
    question:"Can I interview the remote developers before hiring them?",
    answer:"Yes. You can conduct interviews with shortlisted developers to assess their suitability for your project. We facilitate the interview process to ensure you find the best fit for your team.",
  },
  {
    question:"  What are the working hours of remote developers?",
    answer:"Our remote developers can adapt their schedules to match your time zone. You can discuss and establish mutually agreed-upon working hours and communication preferences to ensure seamless collaboration. ",
  },
  
  {
    question:" How are intellectual property rights protected when hiring remote developers?",
    answer:" Destino ensures the protection of your intellectual property by signing comprehensive Non-Disclosure Agreements (NDAs) with developers. We prioritize confidentiality and security throughout the engagement.",
  },
  {
    question:"What if I'm not satisfied with the performance of a hired developer?",
    answer:" We offer a satisfaction guarantee. If you're unsatisfied with a developer's performance, we provide quick replacements, ensuring that you have the right talent for your project needs.",
  },
  {
    question:"Are there any additional costs involved apart from the developer's hourly rate?",
    answer:"No, there are no hidden costs. The hourly rate agreed upon includes all expenses associated with the developer's services. We believe in transparent pricing.",
  },
  {
    question:"How do I communicate and track progress with remote developers?",
    answer:"We facilitate seamless communication through various channels like email, video calls, and project management tools. Additionally, you'll receive regular updates on project progress for transparency and accountability. ",
  },
 
];

export default function ServiceFaq() {
  return (
    <div className="acc-main">
      <div className="acc-container">
        <h3>Frequently Asked Questions</h3>
        <div className="acc">
          {faqData.map((faq, index) => (
            <FaqItem
              key={index}
              question={faq.question}
              answer={faq.answer}
            />
          ))}
        </div>
      
      </div>
    </div>
  );
}
