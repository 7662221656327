import React from 'react'
import { Grid } from "@mui/material";
import icon7 from "./AngImg/Protection1.png";
import icon8 from "./AngImg/Complete2.png";
import icon9 from "./AngImg/hiring3.png";
import icon10 from "./AngImg/Offshore4.png";
import icon11 from "./AngImg/Flexible Hiring5.png";
import icon12 from "./AngImg/Cost-Saving6.png";
import icon13 from "./AngImg/Reduced7.png";
import icon14 from "./AngImg/On-demand Scaling8.png";
import icon15 from "./AngImg/Complete Solution 9.png";


export default function AngC() {
  return (
    <>
    <div className="degree-main">
      <div className="degree-container">
        <h6 className="rea">Expertise, Value, Results</h6>
        <h2>Why Opt for Our AngularJS Team</h2>
        <p className="pe">
          Our Indian ReactJS team offers unparalleled expertise ready to
          transform your project. Committed to value-centric solutions, we
          develop cost-effective, high-quality applications that consistently
          surpass expectations. Trust our dedicated team for your ReactJS
          development needs and witness tangible results.
        </p>
        <div className="degree-card">
          <Grid
            container
            spacing={3}
            sx={{ paddingLeft: 3, paddingRight: 3 }}
          >
            <Grid item xs={12} sm={12} md={4}>
              <div className="grid-item-box ">
                <img src={icon7} alt={""} />
                <h3 className="degree-center "> IP Protection</h3>
                <p className="degree-pra">
                Rigorous measures to safeguard your project's innovations and intellectual property.
                </p>
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <div className="grid-item-box ">
                <img src={icon8} alt={""} />
                <h3 className="degree-center">Complete Data Security</h3>
                <p className="degree-pra">
                Robust protocols ensure comprehensive data protection and confidentiality.
                </p>{" "}
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              {" "}
              <div className="grid-item-box ">
                <img src={icon9} alt={""} />
                <h3 className="degree-center">Flexible Hiring Model</h3>
                <p className="degree-pra">
                Adaptable hiring models provide seamless flexibility aligned with your project's needs.
                </p>{" "}
              </div>
            </Grid>
          </Grid>
        </div>
        <div className="degree-card">
          <Grid
            container
            spacing={3}
            sx={{ paddingLeft: 3, paddingRight: 3 }}
          >
            <Grid item xs={12} sm={12} md={4}>
              <div className="grid-item-box ">
                <img src={icon10} alt={""} />
                <h3 className="degree-center ">100% Project Visibility</h3>
                <p className="degree-pra">
                Absolute transparency, granting you full visibility and control throughout the development cycle.
                </p>
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <div className="grid-item-box ">
                <img src={icon11} alt={""} />
                <h3 className="degree-center"> Large Talent Pool</h3>
                <p className="degree-pra">
                Access to a diverse talent pool of skilled AngularJS professionals, ensuring scalability for various project requirements.
                </p>{" "}
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              {" "}
              <div className="grid-item-box ">
                <img src={icon12} alt={""} />
                <h3 className="degree-center"> Cost Effective</h3>
                <p className="degree-pra">
                Delivering cost-effective solutions for high-quality AngularJS development, maintaining excellence and efficiency.
                </p>{" "}
              </div>
            </Grid>
          </Grid>
        </div>
        <div className="degree-card">
          <Grid
            container
            spacing={3}
            sx={{ paddingLeft: 3, paddingRight: 3 }}
          >
            <Grid item xs={12} sm={12} md={4}>
              <div className="grid-item-box ">
                <img src={icon13} alt={""} />
                <h3 className="degree-center "> No Hidden Charges</h3>
                <p className="-degree-pra">
                Transparent pricing, ensuring clarity and no concealed costs throughout the AngularJS development process.
                </p>
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <div className="grid-item-box ">
                <img src={icon14} alt={""} />
                <h3 className="degree-center"> Quick Resource Replacement</h3>
                <p className="degree-pra">
                Efficient resource management facilitates swift replacement for project continuity and minimal disruptions.
                </p>{" "}
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              {" "}
              <div className="grid-item-box ">
                <img src={icon15} alt={""} />
                <h3 className="degree-center">
                  Interviews with Zero Obligation
                </h3>
                <p className="degree-pra">
                Explore talent and capabilities hassle-free with interviews that come with zero obligations before making commitments.</p>{" "}
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  </>
  )
}
