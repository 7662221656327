import React from "react";
import { Grid } from "@mui/material";
export default function AngD() {
  return (
    <div>
      <div className="degree-main">
        <div className="degree-container">
          <h6 className="rea">Popular React.js Pairings</h6>
          <h2>AngularJs Development Services We Offer</h2>
          <p className="pe">
            Explore our array of popular React.js combinations that elevate your
            project's potential. From React Native for versatile mobile
            solutions to Redux for state management precision, our expertise
            extends to crafting seamless integrations with GraphQL, TypeScript,
            or Next.js. Embrace the power of these combinations tailored to
            optimize performance, scalability, and user experience.
          </p>
          <div className="degree-card">
            <Grid
              container
              spacing={3}
              sx={{ paddingLeft: 3, paddingRight: 3 }}
            >
              <Grid item xs={12} sm={12} md={6}>
                <div className="techd  ">
                  <h3 className="degree-center techa">
                    <span className="dot" />
                    Single-page Application Development
                  </h3>
                  <p className="degree-pra left">
                    Crafting seamless, responsive web experiences using Angular,
                    our team ensures optimal user experiences across devices.
                    Dynamic, adaptive interfaces cater to various screen sizes,
                    enhancing user engagement and navigation.
                  </p>
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <div className="techd ">
                  <h3 className="degree-center techa">
                    {" "}
                    <span className="dot" />
                    Seamless Single-page Application Development
                  </h3>
                  <p className="degree-pra left">
                    Efficiently build fluid web experiences with Single-page
                    Applications (SPAs), enabling dynamic content updates and
                    smooth navigation. Our expertise optimizes speed and
                    responsiveness across diverse devices.
                  </p>{" "}
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                {" "}
                <div className="techd">
                  <h3 className="degree-center techa">
                    {" "}
                    <span className="dot" />
                    E-commerce Solutions
                  </h3>
                  <p className="degree-pra left">
                    Empowering online commerce with secure transactions,
                    intuitive interfaces, scalable inventory management, and
                    flexible payment gateway integration ensures a robust and
                    user-friendly e-commerce experience.
                  </p>{" "}
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <div className="techd ">
                  <h3 className="degree-center techa">
                    {" "}
                    <span className="dot" />
                    Progressive Web Development Expertise
                  </h3>
                  <p className="degree-pra left">
                    Progressive Web Development (PWAs) revolutionizes web
                    experiences, guaranteeing reliability, app-like features,
                    and security. Our solutions deliver optimal performance
                    irrespective of device or network conditions, ensuring
                    enhanced user satisfaction.
                  </p>{" "}
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                {" "}
                <div className="techd ">
                  <h3 className="degree-center techa">
                    {" "}
                    <span className="dot" />
                    Platform Migration and Re-engineering
                  </h3>
                  <p className="degree-pra left">
                    Expertise in smooth platform transfer and re-engineering
                    adheres to Solid Principles. Seamless upgrades to newer
                    Angular versions optimize performance and leverage the
                    latest capabilities.
                  </p>{" "}
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                {" "}
                <div className="techd">
                  <h3 className="degree-center techa">
                    {" "}
                    <span className="dot" />
                    Maintenance and Support
                  </h3>
                  <p className="degree-pra left">
                    Our Angular developers offer comprehensive maintenance and
                    support services, addressing issues promptly, providing
                    timely updates, and implementing ongoing improvements with
                    meticulous attention to detail, ensuring smooth application
                    operations.
                  </p>{" "}
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
}
