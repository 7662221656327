import React from 'react'
import { Grid } from '@mui/material';

export default function VueD() {
  return (
    <div>
    <div className="degree-main">
      <div className="degree-container">
        <h6 className="rea">Popular Vue.js Pairings</h6>
        <h2>Why Choose Vue.js Developers From Destino?</h2>
        <p className="pe">
          Explore our array of popular React.js combinations that elevate your
          project's potential. From React Native for versatile mobile
          solutions to Redux for state management precision, our expertise
          extends to crafting seamless integrations with GraphQL, TypeScript,
          or Next.js. Embrace the power of these combinations tailored to
          optimize performance, scalability, and user experience.
        </p>
        <div className="degree-card">
          <Grid
            container
            spacing={3}
            sx={{ paddingLeft: 3, paddingRight: 3 }}
          >
            <Grid item xs={12} sm={12} md={6}>
              <div className="techd  ">
                <h3 className="degree-center techa">
                  <span className="dot" />
                  Transparency & Integrity
                </h3>
                <p className="degree-pra left">
                We prioritize confidentiality in our work processes, ensuring transparency and providing dedicated specialized resources for projects with evolving requirements and untested business models.
                </p>
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <div className="techd ">
                <h3 className="degree-center techa">
                  {" "}
                  <span className="dot" />
                  Reliable & Experienced
                </h3>
                <p className="degree-pra left">
                Our Vue.js developers bring years of experience to deliver exceptional software solutions, recognized through awards and acknowledgments for their dedication and work. 
                </p>{" "}
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              {" "}
              <div className="techd">
                <h3 className="degree-center techa">
                  {" "}
                  <span className="dot" />
                  Initial Cost-Free Quote
                </h3>
                <p className="degree-pra left">
                During the initial phase, we take care of costs while you focus on your business. Share your idea, and we'll provide a cost estimate within 24 working hours at no charge.
                </p>{" "}
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <div className="techd ">
                <h3 className="degree-center techa">
                  {" "}
                  <span className="dot" />
                  Seamless Project Management 
                </h3>
                <p className="degree-pra left">
                Our experts adeptly manage complexities and ensure continual improvement for superior outcomes without burdening you with hassles.
                </p>{" "}
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              {" "}
              <div className="techd ">
                <h3 className="degree-center techa">
                  {" "}
                  <span className="dot" />
                  Effective Communication Channels 
                </h3>
                <p className="degree-pra left">
                We keep you informed about real-time project status via Skype, Slack, email, and more, ensuring open communication and transparency throughout.
                </p>{" "}
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              {" "}
              <div className="techd">
                <h3 className="degree-center techa">
                  {" "}
                  <span className="dot" />
                  Versatility & Flexibility 
                </h3>
                <p className="degree-pra left">
                Hire developers from Your Team in India according to your convenience and needs. Leveraging Vue.js' versatility, we create complex functions using routing functions and more. 
                
                </p>{" "}
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  </div>
  )
}
