import React from "react";
import { Grid, Button } from "@mui/material";
import "./Services.scss";

import Day from "./S-Img/7-Day Free Trial.png";
import Alig from "./S-Img/Alignment with Sr.Tech-lead.png";
import Free from "./S-Img/Free Consultation.png";
import { Link } from 'react-router-dom';

const benefitsData = [
  {
    img: Day,
    alt: "7-Day Free Trial",
    title: "Free Consultation",
    description:
      "Access a complimentary consultation to discuss your project needs and determine the best developer fit, ensuring alignment with your goals and requirements.",
  },
  {
    img: Alig,
    alt: "Alignment with Sr. Tech-lead",
    title: "Developer's Selection",
    description:
      "Enjoy a streamlined selection process, where you have the opportunity to choose from a pool of skilled and vetted developers, ensuring you find the perfect match for your project.",
  },
  {
    img: Free,
    alt: "Free Consultation",
    title: "On-time Delivery",
    description:
      "Rest assured about timely project completion with Destino's commitment to on-time delivery, ensuring that your project milestones are met as per the agreed-upon schedule.",
  },
];

export default function Benefits() {
  return (
    <div className="ben-main">
      <div className="ben-container">
        <div className="heading">
        <h2 className="ben-head">
          Hiring a Destino developer is hassle-free, efficient, and includes
          three additional benefits.
        </h2>
        </div>
        <div className="ben-card">
          <Grid container spacing={2}>
            {benefitsData.map((benefit, index) => (
              <Grid key={index} item xs={12} sm={4} md={4}>
                <img src={benefit.img} alt={benefit.alt} />
                <h4>{benefit.title}</h4>
                <p>{benefit.description}</p>
              </Grid>
            ))}
          </Grid>
          <Link to="/contact">
          <Button className="btn-def sbtn">get in touch</Button>
          </Link>
        </div>
      </div>
    </div>
  );
}
