import React from "react";
import About from "./Abouts";
import Core from "./core";
import Choose from "./Choose";
import Remote from "../Home/Remote";
import Search from "../Home/Search";
import Footer from "../Home/Footer";
import "../Home/App.scss";
// import
// import { Margin } from "@mui/icons-material";
import {Helmet} from "react-helmet";

export default function AboutM() {
  return (
    <div>
       <Helmet>
                <meta charSet="utf-8" />
                <title>About us  | Destino Infotech</title>
                <meta name="description" content="Helmet application" />
                <link rel="canonical" href="http://mysite.com/example" />
            </Helmet>
      <About />
      <Core />
      <Choose />
      <Remote />
      <Search />
      <Footer />
    </div>
  );
}
