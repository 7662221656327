import React from "react";
import { Grid } from "@mui/material";
// import {
//   Language as LanguageIcon,
//   ImportContacts as ImportContactsIcon,
//   TextFields as TextFieldsIcon,
// } from "@mui/icons-material";
import C from "./S-Img/CostEffective.png"
import Q from "./S-Img/QuickHiring.png"
import H from './S-Img/HighlySecure.png'

export default function YourNext() {
  const developmentItems = [
    {
      icon: C,
      
      head: "Cost Effective",
      sub: "Hire a developer at affordable cost",
      text: "Employ skilled offshore developers at rates ranging from competitive to market-standard prices, tailored to suit your budget, with Destino's dedicated team boasting industry experience.",
      heading: "Zero Infrastructure Cost",
      pra2: "Experience zero infrastructure investment concerns by hiring developers through Destino, where a complete work infrastructure is provided, eliminating the need for additional systems or workspaces.",
      sub3: "Zero Hiring Costs",
      pra3: "- Access a vast pool of vetted and talented developers through Destino, bypassing the time, effort, and expenses typically associated with hiring resources via consultants.",
    },
    {
      icon: Q,

      head: "Quick Hiring",
      sub: "200+ Developers for Hire",
      text: "Choose from over 200 developers across various technologies, all pre-vetted, ensuring a swift hiring process for your project's needs.",
      heading: "Transparent Hiring Process",
      pra2: "Engage in a transparent hiring journey from initial resource shortlisting to final selection, enabling your involvement in every step of the developer hiring process.",
      sub3: "Quick Replacement",
      pra3: "Guaranteeing 100% backup and swift replacement of resources, Destino ensures your satisfaction by quickly replacing any developer whose work doesn't meet your expectations.",
    },
    {
      icon: H,

      head: "Highly Secure",
      sub: "Non-Disclosure Agreements",
      text: "Safeguard your Intellectual Property (IP) and data with extensive Non-Disclosure Agreements (NDAs) and enterprise-grade security systems, ensuring complete confidentiality.",
      heading: "Global Quality Standard",
      pra2: " Rely on our highly skilled and experienced developers who adhere to global coding standards, ensuring the delivery of top-notch quality output.",
      sub3: "Well-defined Process Framework & Security Protocols",
      pra3: "- Developers at Destino follow a well-defined process framework, work hierarchy, reporting structure, and security protocols, ensuring smooth project development while upholding security standards.",
    },
  ];

  return (
    <div className="next-main">
      <div className="next-container">
        <div className="heading">
        <h6 className="rea">Elevate Your Projects with Destino's Expertise</h6>
        <h2 >Why Hire Developers from Destino for your next project?</h2>
        <p>
          At Destino, our dedicated team of skilled developers is committed to
          transforming your vision into reality. With a meticulous eye for
          detail and a passion for innovation, we tailor our expertise to suit
          your project's unique needs. Expect nothing less than top-tier
          proficiency, reliability, and a collaborative approach that ensures
          success at every stage.
        </p></div>
        <div className="next-cards">
          <Grid container spacing={3} sx={{ paddingLeft: 3, paddingRight: 3 }}>
            {developmentItems.map((item, index) => (
              <Grid key={index} item xs={12} sm={4} md={4}>
                <div className="next-card">
                  <img className= "dis" src= {item.icon} alt={item.icon}/>
                 
                  <h3 className="next-head">{item.head}</h3>
                  <h4 className="next-h">{item.sub}</h4>
                  <p className="next-pra">{item.text}</p>
                  <h4 className="next-h">{item.heading}</h4>
                  <p className="next-pra">{item.pra2}</p>
                  <h4 className="next-h">{item.sub3}</h4>
                  <p className="next-pra">{item.pra3}</p>
                </div>
              </Grid>
            ))}
          </Grid>
        </div>
      </div>
    </div>
  );
}
