import React from "react";
import Contact from "./Contact";
import {Helmet} from "react-helmet";

export default function ContactM() {
  return (
    <div>
      <Helmet>
                <meta charSet="utf-8" />
                <title>Contact  | Destino Infotech</title>
                <meta name="description" content="Helmet application" />
                <link rel="canonical" href="http://mysite.com/example" />
            </Helmet>
      <Contact />
    </div>
  );
}
