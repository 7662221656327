import React from "react";
import Grid from "@mui/material/Grid";


import Lottie from "lottie-react";
import hoh from "./images/Animation - 1701253320295.json";
import Find from "./images/find.json";
import Hire from "./images/hire.json";
import "react-phone-number-input/style.css";


import FormButton from "./FormButton";

export default function HowToHire() {
  

  return (
    <div className="why">
      <div className="container">
        <div className="heading">
          <h5 className="rea">FEATURES</h5>
          <h3 className="head">Steps
To Hire Our Remote Developers?
</h3>
        </div>
        <p className="pra3">
          Empower your business with our platform's features, ensuring
          streamlined processes, heightened productivity, and goal attainment.
        </p>

        <div className="how">
          <Grid container spacing={2}>
            {/* Feature Section 1 */}
            <Grid item xs={12} sm={4}>
              <div className="ani">
                <Lottie animationData={hoh} />
              </div>
              <h4>Tell Us The Skill you Need</h4>
              <p>
                Specify the skills you need and access a comprehensive sales
                dashboard for insights.
              </p>
            </Grid>

            {/* Feature Section 2 */}
            <Grid item xs={12} sm={4}>
              <div className="anim">
                <Lottie animationData={Find} />
              </div>
              <h4>We Find the best Talent for you</h4>
              <p>- Keeping you updated on customer and project activities.</p>
            </Grid>

            {/* Feature Section 3 */}
            <Grid item xs={12} sm={4}>
              <div className="ani">
                <Lottie animationData={Hire} />
              </div>
              <h4>Interview, Hire</h4>
              <p>
                make informed decisions, and hire seamlessly, regardless of your
                location.
              </p>
            </Grid>
          </Grid>

          
          <FormButton/>
          

        
    
          
        </div>
      </div>
    </div>
  );
}
